const processRows = (data) => {
  // todo at some point in the future:
  // make it better
  let rows = {};
  for (let i of data.map((i) => ({ ...i, parse: JSON.parse(i.parse) }))) {
    if (i.society === "MLC") i.parse.work_id = i.parse.other_info.society_id;
    if(!i.parse.work_id) i.parse.work_id = i.parse.title;
    if (rows[i.parse.work_id]) {
      rows[i.parse.work_id].ids.push(i.id);
    } else {
      rows[i.parse.work_id] = {
        ids: [i.id],
        society: i.society,
        title: i.parse.title,
      };
      if (!i.parse.title) {
        rows[i.parse.work_id].row = JSON.stringify(i.parse.original_row);
      }
    }
  }
  return Object.keys(rows).map((i) => ({
    society: i.society,
    work_id: i,
    ...rows[i],
  }));
};

export default processRows;
