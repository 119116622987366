const openpgp = require("openpgp");

const decrypt = async (encrypted, key, passphrase) => {
  const privateKey = await openpgp.decryptKey({
    privateKey: await openpgp.readKey({ armoredKey: key }),
    passphrase,
  });
  const msg = await openpgp.readMessage({ armoredMessage: encrypted });
  const { data: decrypted } = await openpgp.decrypt({
    message: msg,
    decryptionKeys: privateKey,
  });
  return decrypted;
};

export default decrypt;
