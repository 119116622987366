import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import axios from "axios";
import apiRoot from "../../apiRoot";
import InfiniteScroll from "react-infinite-scroller";
import WorkComponent from "../createRegistration/WorkComponent";

const CreateRegistration = () => {
  let [registrationState, setRegistrationState] = useState(0);
  let [works, setWorks] = useState([]);
  let [loading, setLoading] = useState(true);
  let [nextPage, setNextPage] = useState(false);
  let [count, setCount] = useState(0);
  let [selectedWorks, setSelectedWorks] = useState([]);
  let [done, setDone] = useState(false);

  const handleChange = (event, newValue) => {
    setRegistrationState(newValue);
    setSelectedWorks([]);
    setWorks([]);
  };

  const getWorks = async (offset) => {
    setLoading(true);
    let filter;
    if (registrationState === 0) filter = "readyStageOne";
    if (registrationState === 1) filter = "readyStageTwo";
    if (registrationState === 2) filter = "readyStageOneRevision";
    if (registrationState === 3) filter = "readyStageTwoRevision";
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getWorks($filter: RegistrationStage, $offset: Int) {
        admin {
          works(filterRegistrationStage: $filter, offset: $offset) {
            count
            isNextPage
            works {
              id
              title
              duration
              iswc
              alternate_titles
              recordings {
                id
                performers
                isrc
              }
              pendingSubmissionRequests {
                first_name
                middle_name
                last_name
              }
              songwriters {
                songwriter {
                  id
                  ipi
                  society
                  first_name
                  middle_name
                  last_name
                }
                controlledBy {
                  id
                }
                share
              }
              created_at
            }
          }
        }
      }`,
        variables: { filter, offset },
      },
      { withCredentials: true }
    );
    setWorks([...works, ...response.data.data.admin.works.works]);
    setNextPage(response.data.data.admin.works.isNextPage);
    setCount(response.data.data.admin.works.count);
    setLoading(false);
  };

  const submitRegistration = async () => {
    let stage,
      type = "new";
    if (registrationState === 0) stage = 1;
    if (registrationState === 1) stage = 2;
    if (registrationState === 2) {
      stage = 1;
      type = "revision";
    }
    if (registrationState === 3) {
      stage = 2;
      type = "revision";
    }
    await axios.post(
      apiRoot,
      {
        query: `
        mutation createRegistration($stage: Int!, $type: BulkRegistrationType!, $works: [Int!]!) {
          admin {
            generateWorkRegistrationFiles(stage: $stage, type: $type, works: $works) {
              id
            }
          }
        }`,
        variables: { stage, type, works: selectedWorks },
      },
      { withCredentials: true }
    );
    setDone(true);
  };

  // eslint-disable-next-line
  useEffect(() => getWorks(0), [registrationState]);

  return (
    <Box m={2}>
      {done && <Redirect to={"/registrations"} />}
      <Typography variant="h4">Create Registration ({count})</Typography>
      <Typography variant="subtitle2">
        Select a registration stage and choose works to include in the
        registration file
      </Typography>
      <Tabs
        value={registrationState}
        onChange={handleChange}
        style={{ margin: "20px 0" }}
      >
        <Tab label="Stage 1" />
        <Tab label="Stage 2" />
        <Tab label="Stage 1 Revision" />
        <Tab label="Stage 2 Revision" />
      </Tabs>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper style={{ padding: "20px" }}>
            <InfiniteScroll
              pageStart={0}
              loadMore={() => {
                if (!loading) getWorks(works.length);
              }}
              hasMore={nextPage}
            >
              {works.map((i) => (
                <Box
                  m={2}
                  key={i.id}
                  style={{
                    paddingBottom: "15px",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  <WorkComponent work={i} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedWorks.includes(i.id)}
                        onChange={(e) => {
                          if (!e.target.checked)
                            setSelectedWorks(
                              selectedWorks.filter((x) => x !== i.id)
                            );
                          else setSelectedWorks([...selectedWorks, i.id]);
                        }}
                      />
                    }
                    label="Include in registration"
                  />
                </Box>
              ))}
            </InfiniteScroll>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: "20px" }}>
            {works
              .filter((i) => selectedWorks.includes(i.id))
              .map((i) => (
                <Box
                  m={2}
                  key={i.id}
                  style={{
                    paddingBottom: "15px",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  <WorkComponent work={i} />
                </Box>
              ))}
            <Button
              variant="contained"
              color="primary"
              disabled={selectedWorks.length === 0}
              onClick={submitRegistration}
            >
              Create registration
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateRegistration;
