import { TextField, Button } from "@material-ui/core";
import React, { useState } from "react";
import axios from "axios";
import apiRoot from "../../apiRoot";

const UploadStatement = (props) => {
  let [society, setSociety] = useState("");
  let [territory, setTerritory] = useState("");
  let [period, setPeriod] = useState("");
  let [periodStart, setPeriodStart] = useState("");
  let [periodEnd, setPeriodEnd] = useState("");
  let [paymentDate, setPaymentDate] = useState("");
  let [paymentAmount, setPaymentAmount] = useState("");
  let [currency, setCurrency] = useState("");
  let [exchangeRate, setExchangeRate] = useState("");
  let [file, setFile] = useState("");
  let [working, setWorking] = useState(false);

  const submit = async () => {
    setWorking(true);
    // Get object storage upload info
    const uploadTokenReq = await axios.post(
      apiRoot,
      {
        query: `
      mutation GetToken($extension: String!) {
        admin {
          getStatementUploadToken(extension: $extension)
        }
      }`,
        variables: { extension: file.name.split(".").pop() },
      },
      { withCredentials: true }
    );
    const uploadTokenData = JSON.parse(
      uploadTokenReq.data.data.admin.getStatementUploadToken
    );
    await fetch(uploadTokenData.url, {
      method: "PUT",
      body: file,
    });
    // Process statement
    let vars = {
      extension: file.name.split(".").pop(),
      society,
      territory,
      period,
      period_start: String(new Date(periodStart + "-01")),
      period_end: String(
        new Date(periodEnd.substr(0, 4), periodEnd.substr(5), 0)
      ),
      payment_date: String(new Date(paymentDate)),
      payment_amount: Number(paymentAmount),
      client_statement_month: Number(props.id),
    };
    if (currency) vars.currency = currency;
    if (exchangeRate) vars.exchange_rate = Number(exchangeRate);
    await axios.post(
      apiRoot,
      {
        query: `
      mutation ImportStatement($id: Int!, $statement: StatementInput!) {
        admin {
          importStatement(id: $id, statement: $statement)
        }
      }`,
        variables: {
          id: uploadTokenData.id,
          statement: vars,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
  };

  const margin = { marginTop: "10px" };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TextField
        label="Society"
        value={society}
        onChange={(e) => setSociety(e.target.value)}
      />
      <TextField
        label="Territory"
        value={territory}
        onChange={(e) => setTerritory(e.target.value)}
        style={margin}
      />
      <TextField
        label="Period (text)"
        value={period}
        onChange={(e) => setPeriod(e.target.value)}
        style={margin}
      />
      <TextField
        label="Period start month (YYYY-MM)"
        value={periodStart}
        onChange={(e) => setPeriodStart(e.target.value)}
        style={margin}
      />
      <TextField
        label="Period end month"
        value={periodEnd}
        onChange={(e) => setPeriodEnd(e.target.value)}
        style={margin}
      />
      <TextField
        label="Payment date"
        value={paymentDate}
        onChange={(e) => setPaymentDate(e.target.value)}
        style={margin}
      />
      <TextField
        label="Payment amount"
        value={paymentAmount}
        onChange={(e) => setPaymentAmount(e.target.value)}
        style={margin}
      />
      <TextField
        label="Currency"
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
        style={margin}
      />
      <TextField
        label="Exchange rate"
        value={exchangeRate}
        onChange={(e) => setExchangeRate(e.target.value)}
        style={margin}
      />
      <input
        id="statementUpload"
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
        style={{ display: "none" }}
      />
      <label htmlFor="statementUpload" style={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          component="span"
          style={{ marginRight: "10px" }}
        >
          Choose file
        </Button>
        {file ? <span>{file.name}</span> : <span>No file selected</span>}
      </label>
      <Button
        variant="contained"
        color={working ? "disabled" : "primary"}
        style={{ marginTop: "20px" }}
        onClick={submit}
        disabled={working}
      >
        Upload statement
      </Button>
    </div>
  );
};

export default UploadStatement;
