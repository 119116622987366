import React, { useState } from "react";
import { Typography, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SongSearch from "../matchingTool/SongSearch";
import { format } from "date-fns";

const ManualStatement = (props) => {
  let [rows, setRows] = useState([]);
  // statement
  let [society, setSociety] = useState("");
  let [territory, setTerritory] = useState("");
  let [period, setPeriod] = useState("");
  let [periodStart, setPeriodStart] = useState("");
  let [periodEnd, setPeriodEnd] = useState("");
  let [paymentDate, setPaymentDate] = useState("");
  let [paymentAmount, setPaymentAmount] = useState("");
  let [currency, setCurrency] = useState("");
  let [exchangeRate, setExchangeRate] = useState("");
  // current row
  let [workId, setWorkId] = useState("");
  let [licensee, setLicensee] = useState("");
  let [units, setUnits] = useState("");
  let [totalAmount, setTotalAmount] = useState("");
  let [royaltyType, setRoyaltyType] = useState("");
  let [useType, setUseType] = useState("");
  let [useSubtype, setUseSubtype] = useState("");
  let [working, setWorking] = useState(false);

  const submit = async () => {
    setWorking(true);
    // Process statement
    let vars = {
      society,
      territory,
      period,
      period_start: String(new Date(periodStart + "-01")),
      period_end: String(
        new Date(periodEnd.substr(0, 4), periodEnd.substr(5), 0)
      ),
      payment_date: String(new Date(paymentDate)),
      payment_amount: Number(paymentAmount),
      client_statement_month: Number(props.match.params.id),
    };
    if (currency) vars.currency = currency;
    if (exchangeRate) vars.exchange_rate = Number(exchangeRate);
    let processedRows = rows.map((i) => ({
      ...i,
      period_start: format(new Date(vars.period_start), "yyyy-MM-dd"),
      period_end: format(new Date(vars.period_end), "yyyy-MM-dd"),
      territory: vars.territory,
    }));
    await axios.post(
      apiRoot,
      {
        query: `
      mutation Commit($rows: [RoyaltyRowInput!]!, $statement: StatementInput!) {
        admin {
          createManualStatement(rows: $rows, statement: $statement)
        }
      }`,
        variables: {
          rows: processedRows,
          statement: vars,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
  };

  const push = () => {
    setRows([
      ...rows,
      {
        work_id: workId,
        licensee,
        units: Number(units),
        total_amount: Number(totalAmount),
        royalty_type: royaltyType,
        use_type: useType,
        use_subtype: useSubtype,
      },
    ]);
    setWorkId(null);
    setLicensee("");
    setUnits("");
    setTotalAmount("");
    setRoyaltyType("");
    setUseType("");
    setUseSubtype("");
  };

  const margin = { marginTop: "10px" };

  return (
    <Box m={2}>
      <Typography variant="h4">Manual Statement Tool</Typography>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={6}>
          <Paper style={{ padding: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Society"
                value={society}
                onChange={(e) => setSociety(e.target.value)}
              />
              <TextField
                label="Territory"
                value={territory}
                onChange={(e) => setTerritory(e.target.value)}
                style={margin}
              />
              <TextField
                label="Period (text)"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
                style={margin}
              />
              <TextField
                label="Period start month (YYYY-MM)"
                value={periodStart}
                onChange={(e) => setPeriodStart(e.target.value)}
                style={margin}
              />
              <TextField
                label="Period end month"
                value={periodEnd}
                onChange={(e) => setPeriodEnd(e.target.value)}
                style={margin}
              />
              <TextField
                label="Payment date"
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                style={margin}
              />
              <TextField
                label="Payment amount"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                style={margin}
              />
              <TextField
                label="Currency"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                style={margin}
              />
              <TextField
                label="Exchange rate"
                value={exchangeRate}
                onChange={(e) => setExchangeRate(e.target.value)}
                style={margin}
              />
              <Button
                variant="contained"
                color={working || !rows ? "disabled" : "primary"}
                style={{ marginTop: "20px" }}
                onClick={submit}
                disabled={(false && working) || !rows}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: "20px" }}>
            {rows &&
              rows
                .sort((a, b) => b.amount - a.amount)
                .map((i, n) => (
                  <Box
                    m={2}
                    key={n}
                    style={{
                      paddingBottom: "15px",
                      borderBottom: "1px solid rgba(0,0,0,0.1)",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6">
                      Work ID {i.work_id} - ${i.total_amount}
                    </Typography>
                  </Box>
                ))}
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={6}>
          <SongSearch work={workId} select={setWorkId} />
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Licensee"
                value={licensee}
                onChange={(e) => setLicensee(e.target.value)}
              />
              <TextField
                label="Units"
                value={units}
                onChange={(e) => setUnits(e.target.value)}
                style={margin}
              />
              <TextField
                label="Total amount"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                style={margin}
              />
              <TextField
                label="Royalty type"
                value={royaltyType}
                onChange={(e) => setRoyaltyType(e.target.value)}
                style={margin}
              />
              <TextField
                label="Use type"
                value={useType}
                onChange={(e) => setUseType(e.target.value)}
                style={margin}
              />
              <TextField
                label="Use subtype"
                value={useSubtype}
                onChange={(e) => setUseSubtype(e.target.value)}
                style={margin}
              />
              <Button
                variant="contained"
                color={!workId ? "disabled" : "primary"}
                style={{ marginTop: "20px" }}
                onClick={push}
                disabled={!workId}
              >
                Push
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManualStatement;
