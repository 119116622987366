import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import axios from "axios";
import apiRoot from "../../apiRoot";
import { format } from "date-fns";
import WorkComponent from "../createRegistration/WorkComponent";

const Acknowledgement = (props) => {
  let [acknowledgement, setAcknowledgement] = useState(null);

  const getAcknowledgement = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getAcknowledgement($id: Int!) {
        admin {
          acknowledgement(id: $id) {
            id
            created_at
            society
            link
            acknowledgements {
              id
              status
              error
              society_id
              messages {
                message_type
                original_sequence_number
                record_type
                message_level
                validation_number
                message_text
              }
              work {
                id
                title
                duration
                iswc
                alternate_titles
                recordings {
                  id
                  performers
                  isrc
                }
                pendingSubmissionRequests {
                  first_name
                  middle_name
                  last_name
                }
                songwriters {
                  songwriter {
                    id
                    ipi
                    society
                    first_name
                    middle_name
                    last_name
                  }
                  controlledBy {
                    id
                  }
                  share
                }
                created_at
              }
            }
          }
        }
      }`,
        variables: { id: Number(props.match.params.id) },
      },
      { withCredentials: true }
    );
    setAcknowledgement(response.data.data.admin.acknowledgement);
  };

  // eslint-disable-next-line
  useEffect(() => getAcknowledgement(), [setAcknowledgement]);

  if (!acknowledgement)
    return <Typography variant="subtitle1">Loading...</Typography>;
  if (acknowledgement)
    return (
      <Box m={2}>
        <Typography variant="h4">{acknowledgement.society}</Typography>
        <Typography variant="subtitle2" style={{ marginBottom: "20px" }}>
          Created{" "}
          {format(new Date(acknowledgement.created_at), "MMMM d, yyyy, h:mm a")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component="a"
          href={acknowledgement.link}
        >
          Download CWR
        </Button>
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={6}>
            <Paper style={{ padding: "20px 10px" }}>
              <Typography variant="h6" style={{ marginLeft: "16px" }}>
                Works included with errors or messages (
                {
                  acknowledgement.acknowledgements.filter((i) => i.messages)
                    .length
                }
                )
              </Typography>
              {acknowledgement.acknowledgements.filter((i) => i.messages)
                .length === 0 && (
                <Box m={2}>
                  <Typography variant="body2">No works with errors!</Typography>
                </Box>
              )}
              {acknowledgement.acknowledgements
                .filter((i) => i.messages)
                .map((i) => (
                  <Box
                    m={2}
                    key={i.work.id}
                    style={{
                      paddingBottom: "15px",
                      borderBottom: "1px solid rgba(0,0,0,0.1)",
                    }}
                  >
                    <WorkComponent work={i.work} />
                    <br />
                    <Typography variant="caption">MESSAGES</Typography>
                    {i.messages.map((message) => (
                      <div>
                        <br />
                        <Typography variant="body2">
                          Text: {message.message_text}
                        </Typography>
                        <Typography variant="body2">
                          Message Type: {message.message_type}
                        </Typography>
                        <Typography variant="body2">
                          Original Sequence #:{" "}
                          {message.original_sequence_number}
                        </Typography>
                        <Typography variant="body2">
                          Record Type: {message.record_type}
                        </Typography>
                        <Typography variant="body2">
                          Message Level: {message.message_level}
                        </Typography>
                        <Typography variant="body2">
                          Validation Number: {message.validation_number}
                        </Typography>
                      </div>
                    ))}
                    <br />
                    <Typography variant="body2">
                      Registration Status: {i.status}
                    </Typography>
                    {i.society_id && (
                      <Typography variant="body2">
                        Society ID: {i.society_id}
                      </Typography>
                    )}
                  </Box>
                ))}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{ padding: "20px 10px" }}>
              <Typography variant="h6" style={{ marginLeft: "16px" }}>
                Other works included (
                {
                  acknowledgement.acknowledgements.filter((i) => !i.messages)
                    .length
                }
                )
              </Typography>
              {acknowledgement.acknowledgements
                .filter((i) => !i.messages)
                .map((i) => (
                  <Box
                    m={2}
                    key={i.work.id}
                    style={{
                      paddingBottom: "15px",
                      borderBottom: "1px solid rgba(0,0,0,0.1)",
                    }}
                  >
                    <WorkComponent work={i.work} />
                    <br />
                    <Typography variant="body2">
                      Registration Status: {i.status}
                    </Typography>
                    {i.society_id && (
                      <Typography variant="body2">
                        Society ID: {i.society_id}
                      </Typography>
                    )}
                  </Box>
                ))}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
};

export default Acknowledgement;
