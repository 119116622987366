import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Paper from "@material-ui/core/Paper";
import { TextField, Button } from "@material-ui/core";
import WorkComponent from "../createRegistration/WorkComponent";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const SongSearch = (props) => {
  let [query, setQuery] = useState("");
  let [songs, setSongs] = useState([]);

  const search = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
        query getMatchData($query: GenericString!) {
          admin {
            workSearch(query: $query) {
              id
              title
              duration
              iswc
              alternate_titles
              recordings {
                id
                performers
                isrc
              }
              pendingSubmissionRequests {
                first_name
                middle_name
                last_name
              }
              songwriters {
                songwriter {
                  id
                  ipi
                  society
                  first_name
                  middle_name
                  last_name
                }
                controlledBy {
                  id
                }
                share
              }
              created_at
            }
          }
        }`,
        variables: { query },
      },
      { withCredentials: true }
    );
    setSongs(response.data.data.admin.workSearch);
  };

  return (
    <Paper style={{ padding: "20px" }}>
      <form
        style={{ display: "flex" }}
        onSubmit={(e) => {
          e.preventDefault();
          search();
        }}
      >
        <TextField
          label="Query"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          variant="outlined"
          style={{ flex: "1" }}
        />
        <IconButton type="submit">
          <SearchIcon />
        </IconButton>
      </form>
      {songs.map((i) => (
        <Box
          m={2}
          key={i.id}
          style={{
            paddingBottom: "15px",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <WorkComponent work={i} />
          <Button
            variant={props.work === i.id ? "outlined" : "contained"}
            color="primary"
            style={{ marginTop: "5px" }}
            onClick={() => props.select(i.id)}
          >
            {props.work === i.id ? "Deselect" : "Select"}
          </Button>
        </Box>
      ))}
    </Paper>
  );
};

export default SongSearch;
