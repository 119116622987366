import {
  Typography,
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import axios from "axios";
import apiRoot from "../../apiRoot";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const WithholdingDashboard = (props) => {
  let [data, setData] = useState(null);
  let [amount, setAmount] = useState("");
  let [date, setDate] = useState("");

  const getExactDate = (day, month, year) => {
    let date = new Date();
    date.setUTCHours(0, 0, 0, 0);
    date.setFullYear(year);
    date.setMonth(month);
    date.setDate(day);
    return date;
  };

  const calcPeriodTotal = (data, periodStart, periodEnd) => {
    return data.payments
      .filter(
        (i) =>
          new Date(i.created_at) >= periodStart &&
          new Date(i.created_at) < periodEnd
      )
      .map((i) => i.tax_withheld)
      .reduce((total, c) => total + c, 0);
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getInfo = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getWithholding {
        admin {
          withholdingBalance
          withholdingPayments {
            amount
            date
            created_at
          }
          payments {
            tax_withheld
            created_at
          }
        }
      }`,
      },
      { withCredentials: true }
    );
    // Calculate withholding periods; working backward from current month to the first payment
    // First, get the first payment date
    const earliest_date = Math.min.apply(
      null,
      response.data.data.admin.payments.map((i) =>
        Number(new Date(i.created_at))
      )
    );
    // Then work backward by month
    let currentMonth = new Date().getMonth(),
      currentYear = new Date().getFullYear();
    let comparisonMonth =
      new Date(earliest_date).getMonth() +
      new Date(earliest_date).getFullYear() * 12;
    let arr = [];
    let fullYearsCreated = [];
    while (comparisonMonth <= currentMonth + currentYear * 12) {
      // Check if array contains year
      if (!fullYearsCreated.includes(currentYear)) {
        arr.push({
          type: "year",
          year: currentYear,
          total: calcPeriodTotal(
            response.data.data.admin,
            getExactDate(0, 0, currentYear),
            getExactDate(0, 0, currentYear + 1)
          ),
        });
        fullYearsCreated.push(currentYear);
      }
      // Push into array the month and then each of its quarter-months
      arr.push({
        type: "month",
        month: currentMonth,
        year: currentYear,
        total: calcPeriodTotal(
          response.data.data.admin,
          getExactDate(0, currentMonth, currentYear),
          getExactDate(0, currentMonth + 1, currentYear)
        ),
      });
      arr.push({
        type: "qm",
        startDay: 1,
        endDay: 7,
        month: currentMonth,
        year: currentYear,
        total: calcPeriodTotal(
          response.data.data.admin,
          getExactDate(0, currentMonth, currentYear),
          getExactDate(7, currentMonth, currentYear)
        ),
      });
      arr.push({
        type: "qm",
        startDay: 8,
        endDay: 15,
        month: currentMonth,
        year: currentYear,
        total: calcPeriodTotal(
          response.data.data.admin,
          getExactDate(7, currentMonth, currentYear),
          getExactDate(15, currentMonth, currentYear)
        ),
      });
      arr.push({
        type: "qm",
        startDay: 16,
        endDay: 22,
        month: currentMonth,
        year: currentYear,
        total: calcPeriodTotal(
          response.data.data.admin,
          getExactDate(15, currentMonth, currentYear),
          getExactDate(22, currentMonth, currentYear)
        ),
      });
      arr.push({
        type: "qm",
        startDay: 23,
        endDay: daysInMonth(currentMonth, currentYear),
        month: currentMonth,
        year: currentYear,
        total: calcPeriodTotal(
          response.data.data.admin,
          getExactDate(22, currentMonth, currentYear),
          getExactDate(0, currentMonth + 1, currentYear)
        ),
      });
      if (currentMonth === 0) {
        currentMonth = 11;
        currentYear = currentYear - 1;
      } else {
        currentMonth = currentMonth - 1;
      }
    }
    setData({ ...response.data.data.admin, periods: arr });
  };

  const makePayment = async () => {
    await axios.post(
      apiRoot,
      {
        query: `
      mutation makePayment($amount: Float!, $date: String!) {
        admin {
          makeWithholdingPayment(amount: $amount, date: $date)
        }
      }`,
        variables: { amount: Number(amount), date },
      },
      { withCredentials: true }
    );
  };

  useEffect(() => {
    getInfo();
    // eslint-disable-next-line
  }, [setData]);

  if (!data) return <Typography variant="subtitle1">Loading...</Typography>;
  return (
    <Box m={2}>
      <Typography variant="h4">Tax Withholding Dashboard</Typography>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={6}>
          <Paper style={{ padding: "20px" }}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography variant="overline">WITHHOLDING BALANCE</Typography>
                <Typography variant="h4">
                  ${data.withholdingBalance.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="overline">PAYABLE DATE</Typography>
                <Typography variant="body1">
                  {data.withholdingBalance >= 2000 &&
                    "Within 3 business days of the end of the quarter-monthly period"}
                  {data.withholdingBalance >= 200 &&
                    data.withholdingBalance < 2000 &&
                    "Within 15 days of the end of the month"}
                  {data.withholdingBalance < 200 &&
                    "By March 15 of the next year"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    label="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <TextField
                    label="Date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    style={{ marginTop: "10px" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}
                    onClick={makePayment}
                  >
                    Record payment
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">Payments</Typography>
          <TableContainer component={Paper} style={{ marginTop: "10px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.withholdingPayments.map((row) => (
                  <TableRow key={row.created_at}>
                    <TableCell>{row.date}</TableCell>
                    <TableCell align="right">
                      ${row.amount.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">Withholding Periods</Typography>
          <Paper style={{ padding: "20px", marginTop: "10px" }}>
            {data.periods.map((period, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  marginBottom: "5px",
                }}
              >
                {period.type === "year" && (
                  <Typography variant="h6" style={{ marginBottom: "10px" }}>
                    {period.year}
                  </Typography>
                )}
                {period.type === "month" && (
                  <Typography variant="subtitle2">
                    {months[period.month]} {period.year}
                  </Typography>
                )}
                {period.type === "qm" && (
                  <Typography variant="body2">
                    {months[period.month]} {period.startDay}-{period.endDay}
                  </Typography>
                )}
                <Typography variant="body2">
                  <strong>${period.total.toFixed(2)}</strong>
                </Typography>
              </div>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WithholdingDashboard;
