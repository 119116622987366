import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import apiRoot from "../../apiRoot";
import InfiniteScroll from "react-infinite-scroller";
import WorkComponent from "../createRegistration/WorkComponent";

const Works = () => {
  let [registrationState, setRegistrationState] = useState(0);
  let [works, setWorks] = useState([]);
  let [loading, setLoading] = useState(true);
  let [nextPage, setNextPage] = useState(false);
  let [count, setCount] = useState(0);

  const handleChange = (event, newValue) => {
    setRegistrationState(newValue);
    setWorks([]);
  };

  const getWorks = async (offset) => {
    setLoading(true);
    let filter;
    if (registrationState === 0) filter = null;
    if (registrationState === 1) filter = "readyStageOne";
    if (registrationState === 2) filter = "readyStageTwo";
    if (registrationState === 3) filter = "readyStageOneRevision";
    if (registrationState === 4) filter = "readyStageTwoRevision";
    if (registrationState === 5) filter = "submitted";
    if (registrationState === 6) filter = "pendingSubmissionRequests";
    if (registrationState === 7) filter = "sentStageOne";
    if (registrationState === 8) filter = "sentStageOneRevision";
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getWorks($filter: RegistrationStage, $offset: Int) {
        admin {
          works(filterRegistrationStage: $filter, offset: $offset) {
            count
            isNextPage
            works {
              id
              title
              duration
              iswc
              alternate_titles
              recordings {
                id
                performers
                isrc
              }
              pendingSubmissionRequests {
                first_name
                middle_name
                last_name
              }
              songwriters {
                songwriter {
                  id
                  ipi
                  society
                  first_name
                  middle_name
                  last_name
                }
                controlledBy {
                  id
                }
                share
              }
              created_at
            }
          }
        }
      }`,
        variables: { filter, offset },
      },
      { withCredentials: true }
    );
    setWorks([...works, ...response.data.data.admin.works.works]);
    setNextPage(response.data.data.admin.works.isNextPage);
    setCount(response.data.data.admin.works.count);
    setLoading(false);
  };

  // eslint-disable-next-line
  useEffect(() => getWorks(0), [registrationState]);

  return (
    <Box m={2}>
      <Typography variant="h4">Works ({count})</Typography>
      <Tabs
        value={registrationState}
        onChange={handleChange}
        style={{ margin: "20px 0" }}
      >
        <Tab label="All" />
        <Tab label="Ready Stage 1" />
        <Tab label="Ready Stage 2" />
        <Tab label="Ready Stage 1 Revision" />
        <Tab label="Ready Stage 2 Revision" />
        <Tab label="Submitted" />
        <Tab label="Pending Submission Requests" />
        <Tab label="Sent Stage 1" />
        <Tab label="Sent Stage 1 Revision" />
      </Tabs>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: "20px" }}>
            <InfiniteScroll
              pageStart={0}
              loadMore={() => {
                if (!loading) getWorks(works.length);
              }}
              hasMore={nextPage}
            >
              {works.map((i) => (
                <Box
                  m={2}
                  key={i.id}
                  style={{
                    paddingBottom: "15px",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  <WorkComponent work={i} />
                </Box>
              ))}
            </InfiniteScroll>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Works;
