import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import axios from "axios";
import apiRoot from "../../apiRoot";
import { format } from "date-fns";
import WorkComponent from "../createRegistration/WorkComponent";

const Registration = (props) => {
  let [registration, setRegistration] = useState(null);

  const getRegistration = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getRegistration($id: Int!) {
        admin {
          bulkRegistration(id: $id) {
            id
            created_at
            society
            sequenceNumber
            link
            workRegistrations {
              work {
                id
                title
                duration
                iswc
                alternate_titles
                recordings {
                  id
                  performers
                  isrc
                }
                pendingSubmissionRequests {
                  first_name
                  middle_name
                  last_name
                }
                songwriters {
                  songwriter {
                    id
                    ipi
                    society
                    first_name
                    middle_name
                    last_name
                  }
                  controlledBy {
                    id
                  }
                  share
                }
                created_at
              }
            }
          }
        }
      }`,
        variables: { id: Number(props.match.params.id) },
      },
      { withCredentials: true }
    );
    setRegistration(response.data.data.admin.bulkRegistration);
  };

  // eslint-disable-next-line
  useEffect(() => getRegistration(), [setRegistration]);

  if (!registration)
    return <Typography variant="subtitle1">Loading...</Typography>;
  if (registration)
    return (
      <Box m={2}>
        <Typography variant="h4">
          {registration.society}{" "}
          {new Date(registration.created_at).getFullYear()} #
          {registration.sequenceNumber}
        </Typography>
        <Typography variant="subtitle2" style={{ marginBottom: "20px" }}>
          Created{" "}
          {format(new Date(registration.created_at), "MMMM d, yyyy, h:mm a")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component="a"
          href={registration.link}
        >
          Download CWR
        </Button>
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Paper style={{ padding: "20px 10px" }}>
              <Typography variant="h6" style={{ marginLeft: "16px" }}>
                Works included ({registration.workRegistrations.length})
              </Typography>
              {registration.workRegistrations.map((i) => (
                <Box
                  m={2}
                  key={i.work.id}
                  style={{
                    paddingBottom: "15px",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  <WorkComponent work={i.work} />
                </Box>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
};

export default Registration;
