import {
  FormControl,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import apiRoot from "../../apiRoot";
import { format } from "date-fns";

const InviteTable = React.memo((props) => (
  <div style={{ display: "flex", height: "calc(100% - 68px)" }}>
    <div style={{ flexGrow: 1 }}>
      <DataGrid
        rows={props.invites}
        columns={[
          {
            field: "name",
            headerName: "Name",
            width: 150,
          },
          {
            field: "code",
            headerName: "Invite code",
            sortable: false,
            width: 400,
            renderCell: (params) => (
              <a
                style={{ color: "black" }}
                href={"https://my.music.ceo/activateInvite/" + params.value}
              >
                {params.value}
              </a>
            ),
          },
          {
            field: "type",
            headerName: "Type",
            width: 100,
          },
          {
            field: "consumed",
            headerName: "Consumed",
            width: 130,
          },
          {
            field: "created_at",
            headerName: "Creation date",
            width: 250,
            valueGetter: (params) => {
              return format(new Date(params.value), "MMMM d, yyyy, h:mm a");
            },
          },
          {
            field: "invitedBy",
            headerName: "Invited by",
            width: 150,
          },
        ]}
      />
    </div>
  </div>
));

const Invites = () => {
  let [invites, setInvites] = useState([]);
  let [name, setName] = useState("");
  let [type, setType] = useState("songwriter");

  const getInvites = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getInvites {
            referrer {
              getInvites {
                id
                name
                code
                type
                consumed
                created_at
                invitedBy {
                  name
                }
              }
            }
          }`,
      },
      { withCredentials: true }
    );
    setInvites(
      response.data.data.referrer.getInvites.map((i) => ({
        ...i,
        invitedBy: i.invitedBy.name,
      }))
    );
  };

  const invite = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
          mutation invite($name: Name!, $type: InviteType!) {
            referrer {
              inviteUser(name: $name, type: $type) {
                id
                name
                code
                type
                consumed
                created_at
                invitedBy {
                  name
                }
              }
            }
          }`,
        variables: { name, type },
      },
      { withCredentials: true }
    );
    setInvites([
      ...invites,
      ...[response.data.data.referrer.inviteUser].map((i) => ({
        ...i,
        invitedBy: i.invitedBy.name,
      })),
    ]);
  };

  // eslint-disable-next-line
  useEffect(() => getInvites(), [setInvites]);

  const hc1 = (e) => setName(e.target.value);
  const hc2 = (e) => setType(e.target.value);

  return (
    <Box m={2}>
      <Typography variant="h4">Invites ({invites.length})</Typography>
      <Typography variant="subtitle2">
        Invitations for users to create an account on Music Inc., whether as a
        user only or as a publishing client
      </Typography>
      <Paper style={{ padding: "20px", marginTop: "20px", height: "800px" }}>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "flex-end",
            maxWidth: "600px",
          }}
        >
          <TextField
            label="Name"
            value={name}
            onChange={hc1}
            style={{ flex: 1 }}
          />
          <FormControl style={{ flex: 1, margin: "0 20px" }}>
            <Select value={type} onChange={hc2}>
              <MenuItem value={"user_only"}>User account only</MenuItem>
              <MenuItem value={"songwriter"}>Songwriter</MenuItem>
              <MenuItem value={"publisher"}>Publisher</MenuItem>
            </Select>
          </FormControl>
          <Button color="primary" onClick={invite}>
            Invite user
          </Button>
        </div>
        <InviteTable invites={invites} />
      </Paper>
    </Box>
  );
};

export default Invites;
