import { Typography, TextField, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import apiRoot from "../../apiRoot";
import { Link } from "react-router-dom";

const StatementMonths = () => {
  let [statementMonths, setStatementMonths] = useState([]);
  let [month, setMonth] = useState("");
  let [year, setYear] = useState("");

  const getStatementMonths = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getStatementMonths {
        admin {
          clientStatementPeriods {
            id
            statement_month
            finalized
            statements {
              payment_amount
              currency
              exchange_rate
            }
          }
        }
      }`,
      },
      { withCredentials: true }
    );
    setStatementMonths(response.data.data.admin.clientStatementPeriods);
  };

  const createStatementMonth = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
          mutation createStatementMonth($month: Int!, $year: Int!) {
            admin {
              createClientStatementMonth(month: $month, year: $year) {
                id
                statement_month
                statements {
                  payment_amount
                }
              }
            }
          }`,
        variables: { month: Number(month), year: Number(year) },
      },
      { withCredentials: true }
    );
    setStatementMonths([
      response.data.data.admin.createClientStatementMonth,
      ...statementMonths,
    ]);
  };

  // eslint-disable-next-line
  useEffect(() => getStatementMonths(), [setStatementMonths]);

  return (
    <Box m={2}>
      <Typography variant="h4">Statement Periods</Typography>
      <Typography variant="subtitle2">
        Statements are sent to clients on a monthly basis. Add statements
        received from societies to the period in which they were received.
      </Typography>
      <Paper style={{ padding: "5px 20px", marginTop: "20px" }}>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "flex-end",
            maxWidth: "600px",
          }}
        >
          <TextField
            label="Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            style={{ flex: 1, marginRight: "20px" }}
          />
          <TextField
            label="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            style={{ flex: 1, marginRight: "20px" }}
          />
          <Button color="primary" onClick={createStatementMonth}>
            Create new statement period
          </Button>
        </div>
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: "20px", marginTop: "20px" }}>
            {statementMonths.map((i) => (
              <Box
                m={2}
                key={i.id}
                component={Link}
                to={`/statementMonths/${i.id}`}
                style={{
                  paddingBottom: "15px",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  color: "inherit",
                  textDecoration: "none",
                  display: "block",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    display: "inline-block",
                  }}
                >
                  {i.statement_month} ($
                  {i.statements
                    .reduce(
                      (total, s) =>
                        total +
                        (s.currency
                          ? s.payment_amount * s.exchange_rate
                          : s.payment_amount),
                      0
                    )
                    .toFixed(2)}
                  )
                </Typography>
                {i.finalized && (
                  <Typography
                    variant="caption"
                    style={{
                      display: "inline-block",
                      background: "#2f56af",
                      padding: "2px 10px",
                      borderRadius: "4px",
                      marginLeft: "10px",
                      fontWeight: "500",
                      color: "#fff",
                    }}
                  >
                    FINALIZED
                  </Typography>
                )}
              </Box>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatementMonths;
