import React, { useState } from "react";
import { Typography, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const MarketShareDistribution = (props) => {
  let [rows, setRows] = useState(null);
  let [society, setSociety] = useState("");
  let [territory, setTerritory] = useState("");
  let [period, setPeriod] = useState("");
  let [periodStart, setPeriodStart] = useState("");
  let [periodEnd, setPeriodEnd] = useState("");
  let [spStart, setSpStart] = useState("");
  let [spEnd, setSpEnd] = useState("");
  let [paymentDate, setPaymentDate] = useState("");
  let [paymentAmount, setPaymentAmount] = useState("");
  let [currency, setCurrency] = useState("");
  let [exchangeRate, setExchangeRate] = useState("");
  let [working, setWorking] = useState(false);

  const build = async () => {
    setWorking(true);
    const result = await axios.post(
      apiRoot,
      {
        query: `
      mutation Build($start: Int!, $end: Int!, $society: RoyaltyPayer!, $amount: Float!) {
        admin {
          buildMarketShareDistribution(start_statement_period: $start, end_statement_period: $end, society: $society, amount: $amount) {
            publishingAccount {
              id
              name
            }
            amount
            fee
          }
        }
      }`,
        variables: {
          start: Number(spStart),
          end: Number(spEnd),
          society,
          amount: Number(paymentAmount),
        },
      },
      { withCredentials: true }
    );
    setRows(result.data.data.admin.buildMarketShareDistribution);
    setWorking(false);
  };

  const submit = async () => {
    setWorking(true);
    // Process statement
    let vars = {
      society,
      territory,
      period,
      period_start: String(new Date(periodStart + "-01")),
      period_end: String(
        new Date(periodEnd.substr(0, 4), periodEnd.substr(5), 0)
      ),
      payment_date: String(new Date(paymentDate)),
      payment_amount: Number(paymentAmount),
      client_statement_month: Number(props.match.params.id),
    };
    if (currency) vars.currency = currency;
    if (exchangeRate) vars.exchange_rate = Number(exchangeRate);
    await axios.post(
      apiRoot,
      {
        query: `
      mutation Commit($rows: [MarketShareDistributionRow!]!, $statement: StatementInput!) {
        admin {
          commitMarketShareDistribution(rows: $rows, statement: $statement)
        }
      }`,
        variables: {
          rows: rows.map((i) => ({
            amount: i.amount,
            fee: i.fee,
            publishing_account: i.publishingAccount.id,
          })),
          statement: vars,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
  };

  const margin = { marginTop: "10px" };

  return (
    <Box m={2}>
      <Typography variant="h4">Market Share Distribution Tool</Typography>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={6}>
          <Paper style={{ padding: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Society"
                value={society}
                onChange={(e) => setSociety(e.target.value)}
              />
              <TextField
                label="Territory"
                value={territory}
                onChange={(e) => setTerritory(e.target.value)}
                style={margin}
              />
              <TextField
                label="Text Description"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
                style={margin}
              />
              <TextField
                label="Period start month (YYYY-MM)"
                value={periodStart}
                onChange={(e) => setPeriodStart(e.target.value)}
                style={margin}
              />
              <TextField
                label="Period end month"
                value={periodEnd}
                onChange={(e) => setPeriodEnd(e.target.value)}
                style={margin}
              />
              <TextField
                label="Start statement period"
                value={spStart}
                onChange={(e) => setSpStart(e.target.value)}
                style={margin}
              />
              <TextField
                label="End statement period"
                value={spEnd}
                onChange={(e) => setSpEnd(e.target.value)}
                style={margin}
              />
              <TextField
                label="Payment date"
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                style={margin}
              />
              <TextField
                label="Payment amount"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                style={margin}
              />
              <TextField
                label="Currency"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                style={margin}
              />
              <TextField
                label="Exchange rate"
                value={exchangeRate}
                onChange={(e) => setExchangeRate(e.target.value)}
                style={margin}
              />
              <Button
                variant="contained"
                color={working ? "disabled" : "primary"}
                style={{ marginTop: "20px" }}
                onClick={build}
                disabled={working}
              >
                Build
              </Button>
              <Button
                variant="contained"
                color={working || !rows ? "disabled" : "primary"}
                style={{ marginTop: "20px" }}
                onClick={submit}
                disabled={(false && working) || !rows}
              >
                Commit
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: "20px" }}>
            {rows &&
              rows
                .sort((a, b) => b.amount - a.amount)
                .map((i) => (
                  <Box
                    m={2}
                    key={i.publishingAccount.id}
                    style={{
                      paddingBottom: "15px",
                      borderBottom: "1px solid rgba(0,0,0,0.1)",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6">
                      {i.publishingAccount.name}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography variant="h6">
                        ${(i.amount - i.fee).toFixed(2)}
                      </Typography>
                      <Typography variant="caption">
                        ${i.fee.toFixed(2)}
                      </Typography>
                    </div>
                  </Box>
                ))}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarketShareDistribution;
