import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import apiRoot from "../../apiRoot";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const Registrations = () => {
  let [registrations, setRegistrations] = useState([]);

  const getRegistrations = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getRegistrations {
        admin {
          bulkRegistrations {
            id
            created_at
            society
            sequenceNumber
          }
        }
      }`,
      },
      { withCredentials: true }
    );
    setRegistrations(response.data.data.admin.bulkRegistrations);
  };

  // eslint-disable-next-line
  useEffect(() => getRegistrations(), [setRegistrations]);

  return (
    <Box m={2}>
      <Typography variant="h4">Registrations</Typography>
      <Typography variant="subtitle2">
        CWR bulk registration files that have been generated for sending to
        societies
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: "20px", marginTop: "20px" }}>
            {registrations.map((i) => (
              <Box
                m={2}
                key={i.id}
                component={Link}
                to={`/registrations/${i.id}`}
                style={{
                  paddingBottom: "15px",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  color: "inherit",
                  textDecoration: "none",
                  display: "block",
                }}
              >
                <Typography variant="h6">
                  {i.society} {new Date(i.created_at).getFullYear()} #
                  {i.sequenceNumber}
                </Typography>
                <Typography variant="caption">
                  Created{" "}
                  {format(new Date(i.created_at), "MMMM d, yyyy, h:mm a")}
                </Typography>
              </Box>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Registrations;
