import {
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import apiRoot from "../../apiRoot";
import { useEffect } from "reactn";

const SendPaypal = () => {
  let [withdrawals, setWithdrawals] = useState([]);
  let [accepted, setAccepted] = useState([]);
  let [error, setError] = useState(null);

  const getWithdrawals = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
        query getWithdrawals {
          admin {
            payments(filterPaid: false, filterMethod: [paypal], filterTaxApproved: true) {
              id
              amount_paid
              payment_method {
                id
                type
                paypalEmail
              }
              payment_profile {
                id
                name
                owner {
                  name
                  email
                }
              }
              created_at
            }
          }
        }`,
      },
      { withCredentials: true }
    );
    if (response?.data?.data?.admin?.payments)
      setWithdrawals(response.data.data.admin.payments);
  };

  const save = () => {
    localStorage.setItem("paypal_payout_saved", JSON.stringify(accepted));
  };
  const load = () => {
    setAccepted(JSON.parse(localStorage.getItem("paypal_payout_saved")));
  };
  const sendPayout = async () => {
    if (withdrawals.length === 0) return;
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation sendPayout($ids: [Int!]!) {
      admin {
        sendPaypalPayout(ids: $ids)
      }
    }`,
        variables: { ids: accepted },
      },
      { withCredentials: true }
    );
    if (response.data.errors) setError(response.data.errors[0].message);
    else setAccepted([]);
  };

  useEffect(() => {
    getWithdrawals();
  }, []);

  const feeTotal = accepted.reduce((p, c) => {
    const w = withdrawals.filter((i) => i.id === c)[0];
    if (w.amount_paid > 12.5) return w.amount_paid * 0.02 + p;
    else return 0.25 + p;
  }, 0);

  return (
    <div>
      <Box m={2}>
        <Typography variant="h4">Send PayPal Payout</Typography>
        {error && <Typography variant="subtitle1">{error}</Typography>}
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={6}>
            <Paper style={{ padding: "20px" }}>
              {withdrawals.map((i) => (
                <Box
                  m={2}
                  key={i.id}
                  style={{
                    paddingBottom: "15px",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="subtitle1">
                      Payment Profile <strong>{i.payment_profile.name}</strong>{" "}
                      (owned by {i.payment_profile.owner.name} /{" "}
                      {i.payment_profile.owner.email})
                    </Typography>
                    <Typography variant="subtitle1">
                      PayPal Email:{" "}
                      <strong>{i.payment_method.paypalEmail}</strong>
                    </Typography>
                    <Typography variant="subtitle1">
                      Requested{" "}
                      {format(new Date(i.created_at), "yyyy-MM-dd, hh:mm aa")}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography variant="h6">
                      ${i.amount_paid.toFixed(2)}
                    </Typography>
                    <FormControlLabel
                      style={{ marginRight: "0" }}
                      control={
                        <Checkbox
                          checked={accepted.includes(i.id)}
                          onChange={(e) => {
                            if (!e.target.checked)
                              setAccepted(accepted.filter((x) => x !== i.id));
                            else setAccepted([...accepted, i.id]);
                          }}
                        />
                      }
                      label="Accept"
                    />
                  </div>
                </Box>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{ padding: "20px", position: "fixed", width: "40%" }}>
              <Typography variant="overline">TOTAL PAYOUTS</Typography>
              <Typography variant="h6">
                $
                {withdrawals
                  .filter((i) => accepted.includes(i.id))
                  .reduce((p, c) => p + c.amount_paid, 0)
                  .toFixed(2)}
              </Typography>
              <Typography variant="overline">TOTAL FEES</Typography>
              <Typography variant="h6">
                ${feeTotal.toFixed(2)}{" "}
                <small style={{ fontWeight: "normal" }}>
                  ({accepted.length}{" "}
                  {accepted.length === 1 ? "payment" : "payments"})
                </small>
              </Typography>
              <Typography variant="overline">TOTAL</Typography>
              <Typography variant="h6">
                $
                {(
                  withdrawals
                    .filter((i) => accepted.includes(i.id))
                    .reduce((p, c) => p + c.amount_paid, 0) + feeTotal
                ).toFixed(2)}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                onClick={save}
              >
                Save payout
              </Button>
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                onClick={load}
              >
                Load payout
              </Button>
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                onClick={sendPayout}
              >
                Send payout
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SendPaypal;
