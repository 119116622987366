import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { format } from "date-fns";
import apiRoot from "../../apiRoot";

const CommissionDashboard = (props) => {
  let [data, setData] = useState([]);

  const getData = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
          {
            me {
              paymentProfiles {
                transactions {
                  ...on Deposit {
                    created_at
                    amount
                    commission_payments {
                      publishing_account_id
                      publishing_account_name
                      amount
                    }
                  }
                }
              }
            }
          }
        `,
      },
      { withCredentials: true }
    );
    setData(
      response.data.data.me.paymentProfiles[0].transactions.filter(
        (i) => i.commission_payments
      )
    );
  };

  // eslint-disable-next-line
  useEffect(() => getData(), [setData]);

  console.log(data);

  return (
    <Box m={2}>
      <Typography variant="h4">Commission Dashboard</Typography>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        {data.map((item) => (
          <Grid item xs={4}>
            <Paper style={{ padding: "20px", marginTop: "20px" }}>
              <Typography variant="h6">
                {format(new Date(item.created_at), "yyyy-MM-dd")}
              </Typography>
              <Typography variant="subtitle1">
                Total: ${item.amount.toFixed(2)}
              </Typography>
              {item.commission_payments.map((i) => (
                <Box
                  m={2}
                  key={i.publishing_account_id}
                  style={{
                    paddingBottom: "15px",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2">
                    {i.publishing_account_name}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography variant="body2">
                      ${i.amount.toFixed(2)}
                    </Typography>
                  </div>
                </Box>
              ))}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CommissionDashboard;
