import axios from "axios";
import apiRoot from "./apiRoot";

const getAuthState = async (setReferrer) => {
  const authRequest = await axios.post(
    apiRoot,
    {
      query: `
      query getAuthData {
        me {
          admin
          referrer
        }
      }`,
    },
    { withCredentials: true }
  );
  if (
    !authRequest.data.data ||
    !authRequest.data.data.me ||
    (!authRequest.data.data.me.admin && !authRequest.data.data.me.referrer)
  ) {
    window.location = "https://my.music.ceo";
  } else {
    if (!authRequest.data.data.me.admin) setReferrer(true);
  }
};

export default getAuthState;
