import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import apiRoot from "../../apiRoot";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import FileUpload from "./Upload";

const Acknowledgements = () => {
  let [acknowledgements, setAcknowledgements] = useState([]);

  const getAcknowledgements = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getAcknowledgements {
        admin {
          acknowledgements {
            id
            created_at
            society
          }
        }
      }`,
      },
      { withCredentials: true }
    );
    setAcknowledgements(response.data.data.admin.acknowledgements);
  };

  // eslint-disable-next-line
  useEffect(() => getAcknowledgements(), [setAcknowledgements]);

  return (
    <Box m={2}>
      <Typography variant="h4">Acknowledgements</Typography>
      <Typography variant="subtitle2">
        CWR acknowledgement files received and imported from societies
      </Typography>
      <FileUpload refresh={getAcknowledgements} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: "20px", marginTop: "20px" }}>
            {acknowledgements.map((i) => (
              <Box
                m={2}
                key={i.id}
                component={Link}
                to={`/acknowledgements/${i.id}`}
                style={{
                  paddingBottom: "15px",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  color: "inherit",
                  textDecoration: "none",
                  display: "block",
                }}
              >
                <Typography variant="h6">{i.society}</Typography>
                <Typography variant="caption">
                  Created{" "}
                  {format(new Date(i.created_at), "MMMM d, yyyy, h:mm a")}
                </Typography>
              </Box>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Acknowledgements;
