import {
  Typography,
  Box,
  Grid,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import axios from "axios";
import apiRoot from "../../apiRoot";
import decrypt from "../../decrypt";

const TINCheck = (props) => {
  let [decryption] = useGlobal("decryption");
  let [forms, setForms] = useState(null);
  let [decrypted, setDecrypted] = useState(false);
  let [metadata, setMetadata] = useState(
    JSON.parse(localStorage.getItem("tin_check_metadata")) || []
  );

  const getForms = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getForms {
        admin {
          paymentProfiles(filterTaxApproved: false) {
            id
            name
            taxFormType
            fullUnencryptedTaxData
            encryptedTaxData
          }
        }
      }`,
        variables: { id: Number(props.match.params.id) },
      },
      { withCredentials: true }
    );
    setForms(response.data.data.admin.paymentProfiles);
  };

  const editMetadata = async (id, data) => {
    if (metadata.filter((i) => i.id === id)[0]) {
      localStorage.setItem(
        "tin_check_metadata",
        JSON.stringify(
          metadata.map((i) => (i.id === id ? { ...i, ...data } : i))
        )
      );
      setMetadata(JSON.parse(localStorage.getItem("tin_check_metadata")));
    } else {
      localStorage.setItem(
        "tin_check_metadata",
        JSON.stringify([...metadata, { id, ...data }])
      );
      setMetadata(JSON.parse(localStorage.getItem("tin_check_metadata")));
    }
  };

  useEffect(() => {
    async function dec() {
      if (forms && forms.length > 0 && !decrypted && decryption) {
        setDecrypted(true);
        setForms(
          await Promise.all(
            forms.map(async (form) => {
              if (
                !form.encryptedTaxData ||
                typeof form.encryptedTaxData !== "string"
              )
                return form;
              const dec = await decrypt(
                form.encryptedTaxData,
                decryption.key,
                decryption.passphrase
              );
              return { ...form, encryptedData: JSON.parse(dec) };
            })
          )
        );
      }
    }
    dec();
  }, [forms, decrypted, decryption]);

  // eslint-disable-next-line
  useEffect(() => getForms(), [setForms]);

  if (!forms) return <Typography variant="subtitle1">Loading...</Typography>;

  return (
    <Box m={2}>
      {!decryption && <Redirect to="/decryption" />}
      <Typography variant="h4">TIN Confirmation</Typography>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Paper style={{ padding: "20px", width: "100%" }}>
          {forms
            .filter((i) => i.encryptedData && i.encryptedData.tin)
            .map((form) => (
              <Box
                m={2}
                style={{
                  paddingBottom: "15px",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                key={form.id}
              >
                <div>
                  <Typography variant="subtitle1">
                    {form.taxFormType}
                  </Typography>
                  <Typography variant="subtitle1">
                    {JSON.parse(form.fullUnencryptedTaxData).name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {JSON.parse(form.fullUnencryptedTaxData).businessName}
                  </Typography>
                  {form.encryptedData.tinType && (
                    <Typography variant="subtitle1">
                      {form.encryptedData.tinType}
                    </Typography>
                  )}
                  <Typography variant="subtitle1">
                    {form.encryptedData.tin}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0" }}
                    control={
                      <Checkbox
                        checked={
                          metadata.filter((i) => i.id === form.id)[0]?.accepted
                        }
                        onChange={(e) => {
                          editMetadata(form.id, { accepted: e.target.checked });
                        }}
                      />
                    }
                    label="Accept"
                  />
                  <TextField
                    label="Comment"
                    value={metadata.filter((i) => i.id === form.id)[0]?.comment}
                    onChange={(e) =>
                      editMetadata(form.id, { comment: e.target.value })
                    }
                    style={{ marginTop: "5px", width: "300px" }}
                  />
                </div>
              </Box>
            ))}
        </Paper>
      </Grid>
    </Box>
  );
};

export default TINCheck;
