import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import codes from "./society_codes.json";
import axios from "axios";
import apiRoot from "../../apiRoot";

const Dropbox = styled.div`
  width: 100%;
  flex: 1;
  max-height: 58vh;
  min-height: max-content;
  height: ${(props) => (props.compress ? "150px" : "700px")};
  transition: height 0.3s;
  text-align: center;
  cursor: pointer;
  background: white;
  @media screen and (max-width: 700px) {
    height: auto;
  }
`;

const Flex = styled.div`
  border: 3px dashed #3f51b5;
  padding: 45px 20px 45px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) => (props.compress ? "row" : "column")};
  justify-content: center;
  align-items: center;
  height: 100%;
  background: ${(props) => (props.active ? "#3f51b5" : "transparent")};
  color: ${(props) => (props.active ? "white" : "#141414")};
  transition: background 0.2s, color 0.2s;
  :hover {
    background: hsl(220, 100%, 96%);
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const F2 = styled.div`
  text-align: center;
`;

const MainText = styled.h2`
  margin-top: 0;
  margin-bottom: 10px;
`;

const Subtitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  margin: 5px 0;
`;

const BigDiv = styled.div`
  margin: 40px 0 20px 0;
  @media screen and (max-width: 800px) {
    margin: 30px 0;
  }
`;

const Icon = styled.div`
  border: 4px solid ${(props) => (props.active ? "white" : "#3f51b5")};
  color: ${(props) => (props.active ? "white" : "#3f51b5")};
  svg {
    width: 45px;
    height: 45px;
  }
  height: 90px;
  padding: 20px;
  border-radius: 200px;
  display: inline-block;
  margin-bottom: ${(props) => (props.compress ? "0" : "40px")};
  margin-right: ${(props) => (props.compress ? "40px" : "0")};
  transform: scale(${(props) => (props.active ? "1.15" : "1")});
  transition: transform 0.2s, border-color 0.2s, color 0.2s;
  @media screen and (max-width: 700px) {
    margin-bottom: 20px;
    margin-right: 0;
  }
`;

const FileUpload = (props) => {
  const onDrop = async (files) => {
    if (!files || files.length === 0) return;
    const f = files[0];
    // upload it
    const code = f.name.substr(8, 3);
    const society = Object.keys(codes).filter((i) => codes[i] === code)[0];
    const uploadTokenReq = await axios.post(
      apiRoot,
      {
        query: `
      mutation {
        admin {
          getAcknowledgementUploadToken
        }
      }`,
      },
      { withCredentials: true }
    );
    const uploadTokenData = JSON.parse(
      uploadTokenReq.data.data.admin.getAcknowledgementUploadToken
    );
    await fetch(uploadTokenData.url, {
      method: "PUT",
      body: f,
    });
    // Process statement
    let vars = {
      society,
      filename: f.name,
    };
    await axios.post(
      apiRoot,
      {
        query: `
      mutation ImportAcknowledgement($id: Int!, $acknowledgement: AcknowledgementInput!) {
        admin {
          importAcknowledgement(id: $id, acknowledgement: $acknowledgement)
        }
      }`,
        variables: {
          id: uploadTokenData.id,
          acknowledgement: vars,
        },
      },
      { withCredentials: true }
    );
    props.refresh();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    useFsAccessApi: false,
  });

  return (
    <BigDiv>
      <Dropbox compress={true} {...getRootProps()}>
        <input {...getInputProps()} />
        <Flex compress={true} active={isDragActive}>
          <Icon compress={true} active={isDragActive}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
              />
            </svg>
          </Icon>
          <F2>
            <MainText>Drop a new acknowledgement</MainText>
            <Subtitle>or click to choose a file</Subtitle>
          </F2>
        </Flex>
      </Dropbox>
    </BigDiv>
  );
};

export default FileUpload;
