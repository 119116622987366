import { Typography, Box, Grid, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import axios from "axios";
import apiRoot from "../../apiRoot";

const Dashboard = () => {
  let [data, setData] = useState(null);

  const getInfo = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getData {
        admin {
          minimumBalance
        }
      }`,
      },
      { withCredentials: true }
    );
    setData(JSON.parse(response.data.data.admin.minimumBalance));
  };

  useEffect(() => {
    getInfo();
    // eslint-disable-next-line
  }, [setData]);

  if (!data) return <Typography variant="subtitle1">Loading...</Typography>;
  return (
    <Box m={2}>
      <Typography variant="h4">Payments Dashboard</Typography>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={8}>
          <Paper style={{ padding: "20px", display: "flex" }}>
            <div style={{ marginRight: "40px" }}>
              <Typography variant="overline">MINIMUM BALANCE</Typography>
              <Typography variant="h4">${data.total}</Typography>
            </div>
            <div style={{ marginRight: "40px" }}>
              <Typography variant="overline">USER BALANCES</Typography>
              <Typography variant="h6">${data.userBalances}</Typography>
            </div>
            <div style={{ marginRight: "40px" }}>
              <Typography variant="overline">MAX PAYOUT FEES</Typography>
              <Typography variant="h6">${data.payoutFees}</Typography>
            </div>
            <div style={{ marginRight: "40px" }}>
              <Typography variant="overline">UNPAID PAYOUTS</Typography>
              <Typography variant="h6">${data.unpaidPayouts}</Typography>
            </div>
            <div style={{ marginRight: "40px" }}>
              <Typography variant="overline">
                CURRENT STATEMENT PERIOD
              </Typography>
              <Typography variant="h6">
                ${data.currentStatementPeriod}
              </Typography>
            </div>
            <div style={{ marginRight: "20px" }}>
              <Typography variant="overline">TAX WITHHOLDING</Typography>
              <Typography variant="h6">${data.withholding}</Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
