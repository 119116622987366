import { TextField, Button, Typography, Box, Paper } from "@material-ui/core";
import React, { useState } from "react";
import axios from "axios";
import apiRoot from "../../apiRoot";

const LabelJSONImport = (props) => {
  let [json, setJson] = useState("");
  let [working, setWorking] = useState(false);

  const submit = async () => {
    if (working) return;
    setWorking(true);
    await axios.post(
      apiRoot,
      {
        query: `
      mutation ImportStatement($data: String!) {
        admin {
          importLabelStatements(data: $data)
        }
      }`,
        variables: {
          data: json,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
  };

  return (
    <Box m={2}>
      <Typography variant="h4">Label JSON Import</Typography>
      <Typography variant="subtitle2">
        Paste the contents of the upload.json file generated by the label
        royalty application below to import it into the Music Inc. system.
      </Typography>
      <Paper style={{ padding: "20px", marginTop: "20px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            label="JSON"
            value={json}
            onChange={(e) => setJson(e.target.value)}
            multiline
            rows={16}
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            onClick={submit}
          >
            Submit JSON
          </Button>
        </div>
      </Paper>
    </Box>
  );
};

export default LabelJSONImport;
