import React from "react";
import { Typography } from "@material-ui/core";

const WorkComponent = (props) => {
  const parseDuration = (duration) => {
    let minutes = Math.floor(duration / 60),
      seconds = duration % 60;
    return (
      minutes + ":" + (String(seconds).length === 1 ? "0" + seconds : seconds)
    );
  };
  const formatIsrc = (isrc) =>
    isrc.substr(0, 2) +
    "-" +
    isrc.substr(2, 3) +
    "-" +
    isrc.substr(5, 2) +
    "-" +
    isrc.substr(7);
  const formatIswc = (iswc) =>
    iswc[0] + "-" + iswc.substr(1, 9) + "-" + iswc[10];
  const work = props.work;

  return (
    <div>
      <Typography variant="h6">{work.title}</Typography>
      {work.iswc && work.duration && (
        <Typography variant="caption">
          {work.iswc.includes("-") ? work.iswc : formatIswc(work.iswc)} ·{" "}
          {typeof work.duration === "string"
            ? work.duration
            : parseDuration(work.duration)}
        </Typography>
      )}
      {work.iswc && !work.duration && (
        <Typography variant="caption">
          {work.iswc.includes("-") ? work.iswc : formatIswc(work.iswc)}
        </Typography>
      )}
      {work.duration && !work.iswc && (
        <Typography variant="caption">
          {typeof work.duration === "string"
            ? work.duration
            : parseDuration(work.duration)}
        </Typography>
      )}
      {work.alternate_titles && work.alternate_titles.length > 0 && (
        <div style={{ marginTop: "10px" }} />
      )}
      {work.alternate_titles &&
        work.alternate_titles.map((i) => (
          <Typography variant="body2">{i}</Typography>
        ))}
      <div style={{ marginTop: "10px" }} />
      {work.songwriters.map((i) => (
        <div key={i.songwriter.id} style={{ marginTop: "5px" }}>
          <Typography variant="body2">
            <strong>
              {i.songwriter.first_name ? `${i.songwriter.first_name} ` : ""}
              {i.songwriter.middle_name ? `${i.songwriter.middle_name} ` : ""}
              {i.songwriter.last_name}
            </strong>{" "}
            ({i.share}%) {i.controlledBy && i.controlledBy.id && "(controlled)"}
          </Typography>
          <Typography variant="caption" style={{ color: "rgba(0,0,0,0.7)" }}>
            {i.songwriter.ipi && i.songwriter.society
              ? `${i.songwriter.society} — ${i.songwriter.ipi}`
              : "No society affiliation"}
          </Typography>
        </div>
      ))}
      {work.recordings.map((i) => (
        <div key={i.id} style={{ marginTop: "5px" }}>
          {i.isrc && (
            <Typography variant="body2">
              <strong>{i.isrc ? formatIsrc(i.isrc) : "No ISRC"}</strong>
            </Typography>
          )}
          {i.performers && (
            <Typography variant="caption" style={{ color: "rgba(0,0,0,0.7)" }}>
              {i.performers.join(", ")}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

export default WorkComponent;
