import { TextField, Button, Typography, Box, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { useGlobal } from "reactn";

const Decryption = (props) => {
  let [key, setKey] = useState("");
  let [passphrase, setPassphrase] = useState("");
  let [decryption, setDecryption] = useGlobal("decryption");

  const submit = () => {
    setDecryption({ key, passphrase });
    setKey("");
    setPassphrase("");
  };
  const disable = () => {
    setDecryption(null);
  };

  const margin = { marginTop: "10px" };

  if (!decryption)
    return (
      <Box m={2}>
        <Typography variant="h4">Decryption</Typography>
        <Typography variant="subtitle2">
          The Curve25519 private key and passphrase used for decryption of
          secure data must be entered for processing certain payment related
          details, like tax forms and payment methods.
        </Typography>
        <Paper style={{ padding: "20px", marginTop: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              label="Private Key"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              multiline
              rows={16}
              variant="outlined"
            />
            <TextField
              label="Passphrase"
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
              style={margin}
              type="password"
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
              onClick={submit}
            >
              Enable decryption
            </Button>
          </div>
        </Paper>
      </Box>
    );

  if (decryption)
    return (
      <Box m={2}>
        <Typography variant="h4">Decryption</Typography>
        <Paper style={{ padding: "20px", marginTop: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1">
              Decryption of secure data is enabled.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
              onClick={disable}
            >
              Disable decryption
            </Button>
          </div>
        </Paper>
      </Box>
    );
};

export default Decryption;
