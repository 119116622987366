import { Typography, Box, Grid, Paper, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import apiRoot from "../../apiRoot";
import decrypt from "../../decrypt";

const accountTypes = {
  businessSavings: "Business savings",
  businessChecking: "Business checking",
  personalSavings: "Personal savings",
  personalChecking: "Personal checking",
};

const SendPayouts = (props) => {
  let [decryption] = useGlobal("decryption");
  let [payouts, setPayouts] = useState(null);
  let [decrypted, setDecrypted] = useState(false);

  const getPayouts = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getForms {
        admin {
          payments(filterPaid: false, filterMethod: [us_bank, check, international_wire], filterTaxApproved: true) {
            id
            amount_paid
            payment_method {
              id
              type
              bankName
              routingNumber
              swift
              wireAccountNumberType
              accountNumberLast4
              bankAccountType
              payTo
              addressLine1
              addressLine2
              addressCity
              addressState
              addressZip
              encryptedData
            }
            payment_profile {
              id
              name
              taxFormType
              fullUnencryptedTaxData
              owner {
                name
                email
              }
            }
            created_at
          }
        }
      }`,
      },
      { withCredentials: true }
    );
    setPayouts(response.data.data.admin.payments);
  };

  const markPaid = async (id) => {
    await axios.post(
      apiRoot,
      {
        query: `
      mutation approveTax($id: Int!) {
        admin {
          markWithdrawalPaid(id: $id)
        }
      }`,
        variables: { id },
      },
      { withCredentials: true }
    );
    setPayouts(payouts.filter((i) => i.id !== id));
  };

  useEffect(() => {
    async function dec() {
      if (payouts && payouts.length > 0 && !decrypted && decryption) {
        setDecrypted(true);
        setPayouts(
          await Promise.all(
            payouts.map(async (payout) => {
              if (
                !payout.payment_method.encryptedData ||
                typeof payout.payment_method.encryptedData !== "string"
              )
                return payout;
              const dec = await decrypt(
                payout.payment_method.encryptedData,
                decryption.key,
                decryption.passphrase
              );
              return {
                ...payout,
                encryptedData: JSON.parse(dec),
                taxData: JSON.parse(
                  payout.payment_profile.fullUnencryptedTaxData
                ),
              };
            })
          )
        );
      }
    }
    dec();
  }, [payouts, decrypted, decryption]);

  // eslint-disable-next-line
  useEffect(() => getPayouts(), [setPayouts]);

  if (!payouts) return <Typography variant="subtitle1">Loading...</Typography>;

  return (
    <Box m={2}>
      {!decryption && <Redirect to="/decryption" />}
      <Typography variant="h4">Send Payouts</Typography>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <Paper style={{ padding: "20px" }}>
            {payouts.map((i) => (
              <Box
                m={2}
                style={{
                  paddingBottom: "15px",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                key={i.id}
              >
                <div>
                  <Typography variant="subtitle1">
                    Payment Profile <strong>{i.payment_profile.name}</strong>{" "}
                    (owned by {i.payment_profile.owner.name} /{" "}
                    {i.payment_profile.owner.email})
                  </Typography>
                  {i.payment_method.type === "us_bank" && (
                    <div>
                      <Typography variant="subtitle1">
                        {accountTypes[i.payment_method.bankAccountType]} account
                        at {i.payment_method.bankName}
                      </Typography>
                      <Typography variant="subtitle1">
                        Routing Number:{" "}
                        <strong>{i.payment_method.routingNumber}</strong>
                      </Typography>
                      <Typography variant="subtitle1">
                        Account Number:{" "}
                        <strong>{i.encryptedData?.accountNumber}</strong>
                      </Typography>
                      {i.payment_profile.taxFormType === "W9" && i.taxData && (
                        <Typography variant="subtitle1">
                          Address:
                          <br />
                          <Typography variant="body2">
                            {i.taxData.addressLine1}
                          </Typography>
                          <Typography variant="body2">
                            {i.taxData.city}, {i.taxData.state} {i.taxData.zip}
                          </Typography>
                        </Typography>
                      )}
                      {i.payment_profile.taxFormType !== "W9" && i.taxData && (
                        <Typography variant="subtitle1">
                          Address:
                          <br />
                          <Typography variant="body2">
                            {i.taxData.residenceAddressLine1}
                          </Typography>
                          <Typography variant="body2">
                            {i.taxData.residenceAddressCityState},{" "}
                            {i.taxData.residenceAddressCountry}
                          </Typography>
                        </Typography>
                      )}
                    </div>
                  )}
                  {i.payment_method.type === "international_wire" && (
                    <div>
                      <Typography variant="subtitle1">
                        SWIFT/BIC: <strong>{i.payment_method.swift}</strong>
                      </Typography>
                      {i.payment_method.wireAccountNumberType === "IBAN" ? (
                        <Typography variant="subtitle1">
                          IBAN: <strong>{i.encryptedData?.iban}</strong>
                        </Typography>
                      ) : (
                        <Typography variant="subtitle1">
                          Account Number:{" "}
                          <strong>{i.encryptedData?.account}</strong>
                        </Typography>
                      )}
                      {i.encryptedData?.caBankCode && (
                        <Typography variant="subtitle1">
                          CA Bank Code:{" "}
                          <strong>{i.encryptedData.caBankCode}</strong>
                        </Typography>
                      )}
                      {i.encryptedData?.caTransitNum && (
                        <Typography variant="subtitle1">
                          CA Transit #:{" "}
                          <strong>{i.encryptedData.caTransitNum}</strong>
                        </Typography>
                      )}
                      {i.encryptedData?.auBsbCode && (
                        <Typography variant="subtitle1">
                          AU BSB: <strong>{i.encryptedData.auBsbCode}</strong>
                        </Typography>
                      )}
                      {i.encryptedData?.inIfscCode && (
                        <Typography variant="subtitle1">
                          IN IFSC Code:{" "}
                          <strong>{i.encryptedData.inIfscCode}</strong>
                        </Typography>
                      )}
                      {i.encryptedData?.ruInn && (
                        <Typography variant="subtitle1">
                          RU INN: <strong>{i.encryptedData.ruInn}</strong>
                        </Typography>
                      )}
                      {i.encryptedData?.phRouting && (
                        <Typography variant="subtitle1">
                          PH Routing #:{" "}
                          <strong>{i.encryptedData.phRouting}</strong>
                        </Typography>
                      )}
                      {i.encryptedData?.zaBranchCode && (
                        <Typography variant="subtitle1">
                          ZA Branch Code:{" "}
                          <strong>{i.encryptedData.zaBranchCode}</strong>
                        </Typography>
                      )}
                      {i.payment_profile.taxFormType === "W9" && i.taxData && (
                        <Typography variant="subtitle1">
                          Address:
                          <br />
                          <Typography variant="body2">
                            {i.taxData.addressLine1}
                          </Typography>
                          <Typography variant="body2">
                            {i.taxData.city}, {i.taxData.state} {i.taxData.zip}
                          </Typography>
                        </Typography>
                      )}
                      {i.payment_profile.taxFormType !== "W9" && i.taxData && (
                        <Typography variant="subtitle1">
                          Address:
                          <br />
                          <Typography variant="body2">
                            {i.taxData.residenceAddressLine1}
                          </Typography>
                          <Typography variant="body2">
                            {i.taxData.residenceAddressCityState},{" "}
                            {i.taxData.residenceAddressCountry}
                          </Typography>
                        </Typography>
                      )}
                    </div>
                  )}
                  {i.payment_method.type === "check" && (
                    <div>
                      <Typography variant="subtitle1">
                        Paper check payable to {i.payment_method.payTo}
                      </Typography>
                      <Typography variant="subtitle1">
                        {i.payment_method.addressLine1}
                      </Typography>
                      {i.payment_method.addressLine2 && (
                        <Typography variant="subtitle2">
                          {i.payment_method.addressLine2}
                        </Typography>
                      )}
                      <Typography variant="subtitle1">
                        {i.payment_method.addressCity},{" "}
                        {i.payment_method.addressState}{" "}
                        {i.payment_method.addressZip}
                      </Typography>
                    </div>
                  )}
                  <Typography variant="subtitle1">
                    Requested{" "}
                    {format(new Date(i.created_at), "yyyy-MM-dd, hh:mm aa")}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography variant="h6">
                    ${i.amount_paid.toFixed(2)}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "5px" }}
                    onClick={() => markPaid(i.id)}
                  >
                    Mark as paid
                  </Button>
                </div>
              </Box>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SendPayouts;
